const oauth = {
  BASE: 'authorize',
  MAIN: undefined,
  // ACCESS: {
  //   BASE: undefined,
  // },
};

oauth.MAIN = `${oauth.BASE}|Authorize`
// oauth.APPROVE.BASE = `${oauth.BASE}|Approve`

export default {
  ...oauth
}
