<template>
  <RecoveryPassword v-if="$route.name === names.MAIN" />
  <router-view v-else />
</template>

<script>

import names from "./routers/names";
import RecoveryPassword from '@/modules/password/recovery/RecoveryPassword'

export default {
  name: 'RecoveryBreadcrumb',
  components: { RecoveryPassword },
  computed: {
    names: () => names,
  },
}

</script>

<style scoped>

</style>
