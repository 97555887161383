import {ACCOUNTS_INSTANCE} from "@/helper/consts";
import names from '@/modules/oauth/routers/names'
import OAuthBreadcrumbs from "@/modules/oauth/OAuthBreadcrumbs.vue";


export default ([
  {
    path: '/auth',
    name: names.MAIN,
    component: OAuthBreadcrumbs,
    meta: {
      requiredAuth: false,
      instances: [
        ACCOUNTS_INSTANCE
      ]
    },
    // alias: '/recovery/approve',
    children: [
      // {
      //   path: 'approve/:token([0-9a-zA-Z]+)',
      //   name: names.APPROVE.BASE,
      //   component: ApprovePassword,
      //   meta: {
      //     requiredAuth: false,
      //     instances: [
      //       ACCOUNTS_INSTANCE
      //     ]
      //   },
      // },
    ]
  },
])
