<template>
  <v-container
    fluid
    class="container__bg fill-height pa-0"
  >
    <v-row
      no-gutters
      align="center"
      justify="center"
      class="fill-height"
    >
      <v-col
        class="container__content fill-height"
        :class="$vuetify.display.mdAndDown ? 'mobile__container' : ''"
      >
        <v-row
          no-gutters
        >
          <v-col
            cols="12"
            align="center"
          >
            <v-img
              :src="rhtuImg"
              aspect-ratio="1"
              alt="rhtu_logo"
              class="rhtu__img"
            />
          </v-col>
          <div class="technic_work__container mt-5 mb-2">
            <p class="technic_work__title">
              Обратите внимание!
            </p>
            <p class="technic_work__subtitle text-grey-darken-3">
              При авторизации, вы предоставляете разрешение на использование <br v-if="$vuetify.display.width > 1440"> следующих категорий ваших персональных данных:
            </p>
          </div>
          <v-col
            v-if="isLoading"
            cols="12"
            justify="center"
            align="center"
            class="my-10"
          >
            <semipolar-spinner
              :animation-duration="2000"
              :size="$vuetify.display.mdAndUp ? 140 : 60"
              color="#356A9E"
            />
            <p class="info__text mt-2">
              Идет загрузка...
            </p>
          </v-col>
          <v-col
            v-else-if="!technic_work"
            cols="12"
            align="center"
          >
            <v-form
              v-model="valid"
              class="left__inputs"
            >
              <div
                v-if="error"
                class="errors__container"
              >
                <v-alert
                  :value="true"
                  class="inputs__error"
                  outline
                  :icon="false"
                  type="error"
                  variant="text"
                >
                  <p>Произошла ошибка</p>
                </v-alert>
              </div>
              <div
                v-if="fields.length > 0"
                class="text-left pt-5"
              >
                <p
                  v-for="field in fields"
                  :key="`checkbox_${field.name}`"
                  class="mb-5"
                >
                  <v-icon
                    color="#1D71B8"
                    class="mr-3"
                  >
                    fiber_manual_record
                  </v-icon>
                  <span
                    class="text-grey-darken-2"
                  >{{ field.name }}</span>
                </p>
              </div>
              <div
                v-else
                class="errors__container"
              >
                <v-alert
                  :value="true"
                  class="inputs__error"
                  outline
                  :icon="false"
                  type="error"
                  variant="text"
                >
                  <p>Что-то пошло не так...</p>
                </v-alert>
              </div>
            </v-form>
            <v-btn
              :loading="isLoading"
              class="agree_btn"
              @click="authorize()"
            >
              ПРОДОЛЖИТЬ
            </v-btn>
            <v-btn
              :loading="isLoading"
              class="cancel_btn"
              @click="() => { goToLoginPage() }"
            >
              ОТМЕНА
            </v-btn>
            <div class="info__text">
              Если у вас возникли трудности с использованием системы, вопросы или предложения, обратитесь в Единый центр поддержки пользователей по почте
              <a
                href="mailto:support@muctr.ru"
                class="info__text--link"
              >support@muctr.ru</a>.
            </div>
          </v-col>
          <v-col
            v-else
            cols="12"
            align="center"
          >
            <div class="technic_work__container">
              <p class="technic_work__title">
                Ведутся технические работы
              </p>
              <p class="technic_work__subtitle">
                Извините за временные неудобства!
              </p>
            </div>
            <div class="info__text">
              Если у вас возникли трудности с использованием системы, вопросы или предложения, обратитесь в Единый центр поддержки пользователей по почте
              <a
                href="mailto:support@muctr.ru"
                class="info__text--link"
              >support@muctr.ru</a>.
            </div>
          </v-col>
        </v-row>
      </v-col>
    </v-row>
  </v-container>
</template>

<script>
import { mapActions, mapGetters } from 'vuex'
import names from '@/modules/password/routers/names'
import {
  goToLoginPage
} from '@/helper'
import rhtuImg from '@/assets/rhtu.svg'
import { SemipolarSpinner } from "epic-spinners"

export default {
  name: 'OAuthAuthorize',
  components: {
    SemipolarSpinner
  },
  data: function () {
    return {
      valid: false,
      technic_work: false,
      show: false,
      rhtuImg: rhtuImg,
      names: names,
      error: undefined,
      fields: [],
      scopes: []
    }
  },
  computed: {
    ...mapGetters({
      isLoading: 'oauth/isLoading',
      isTokenAlive: "user/isTokenAlive"
    }),
    from () {
      // eslint-disable-next-line no-prototype-builtins
      if (this.$route.query.hasOwnProperty('from')) {
        return this.$route.query.from
      } else {
        return null
      }
    },
  },
  methods: {
    ...mapActions({
      oauthAuthorize: 'oauth/AUTHORIZE',
      oauthCheckAccess: 'oauth/CHECK_ACCESS',
    }),
    goToLoginPage: goToLoginPage,
    authorize () {
      this.oauthAuthorize(
        {
          data: {
            scope: this.$route.query.scope ?? undefined,
            client: this.$route.query.client ?? undefined,
            response_type: this.$route.query.response_type ?? undefined,
            redirect_uri: this.$route.query.redirect_uri ?? undefined,
            state: this.$route.query.state ?? undefined,
            code_challenge: this.$route.query.code_challenge ?? undefined,
          }
        },
      ).then(
        (resp) => {
          document.location.href = resp.redirect_uri
        }
      ).catch(
        (e) => {
          e.then(result => {
              this.error = result
          })
        }
      )
    },
    checkAccess (scope, client) {
      this.oauthCheckAccess(
        {
          data: {
            scope: scope,
            client: client
          }
        },
      ).then(
        (resp) => {
          this.fields = resp
        }
      ).catch(
        (e) => {
          e.then(result => {
            this.error = result
          })
        }
      )
    }
  },
  // eslint-disable-next-line vue/order-in-components
  created () {
    if (this.isTokenAlive && this.$route.query.scope !== undefined && this.$route.query.client !== undefined) {
      this.checkAccess(this.$route.query.scope, this.$route.query.client)
    } else {
      this.goToLoginPage(document.location.href)
    }
  },
}
</script>

<style scoped lang="scss">
.container__bg {
  background: url("/src/assets/right_bg.png") no-repeat;
  background-size: cover;
}

.container__content {
  display: flex;
  justify-content: center;
  align-items: center;
  max-width: 960px;
  min-height: 860px;
  background: white;
  border-radius: 50px;
  z-index: 1;
}

.rhtu__img {
  width: 234px;
  height: 180px;
  margin-bottom: 50px;
}

.left__inputs {
  width: 420px;
}

.errors__container {
  margin: 30px 0;
  height: 20px;
}

.inputs__error {
  padding: 0;
  color: #D64141;
  font-size: 18px;
  font-style: normal;
  font-weight: 500;
  line-height: 20px;
}

.agree_btn {
  margin-top: 20px;
  width: 320px;
  height: 60px;
  border-radius: 10px;
  background: #1D71B8 !important;
  box-shadow: 0 4px 4px 0 rgba(0, 0, 0, 0.25);
  color: #FFF;
  font-size: 18px;
  font-style: normal;
  font-weight: 700;
  line-height: normal;

  &:hover {
    background: #045396 !important;
    box-shadow: 0 5px 5px 0 rgba(0, 0, 0, 0.5);
  }

  &:active {
    background: #045396 !important;
  }
}

.cancel_btn {
  margin-top: 20px;
  margin-left: 20px;
  width: 200px;
  height: 60px;
  border-radius: 10px;
  background: #FFFFFF !important;
  border: #1D71B8 2px solid;
  box-shadow: 0 4px 4px 0 rgba(0, 0, 0, 0.25);
  color: #1D71B8;
  font-size: 18px;
  font-style: normal;
  font-weight: 700;
  line-height: normal;

  &:hover {
    border-color: #045396;
    color: #FFF;
    background: #045396 !important;
    box-shadow: 0 5px 5px 0 rgba(0, 0, 0, 0.5);
  }

  &:active {
    color: #FFF;
    background: #045396 !important;
    border-color: #045396;
  }
}

.info__text {
  margin-top: 100px;
  padding: 0 180px;
  color: #7C7C7C;
  text-align: center;
  font-size: 14px;
  font-style: normal;
  font-weight: 400;
  line-height: normal;

  &--link {
    color: #1D71B8;
    text-decoration: none;
  }
}

.mobile__container {
  max-width: 660px;
}

.technic_work__container {
  padding: 0 50px;
  margin: 100px auto;
}

.technic_work__title {
  margin-bottom: 10px;
  color: #1D71B8;
  text-align: center;
  font-size: 36px;
  font-style: normal;
  font-weight: 400;
  line-height: normal;
}

.technic_work__subtitle {
  color: #969696;
  text-align: center;
  font-size: 18px;
  font-style: normal;
  font-weight: 400;
  line-height: normal;
}

@media screen and (max-width: 1440px){
  .rhtu__img {
    width: 194px;
    height: 150px;
    margin-bottom: 30px;
  }

  .info__text {
    margin-top: 50px;
  }
}

@media screen and (max-width: 1279px){
  .container__content {
    padding-top: 20px !important;
    max-height: 600px;
    border-radius: 25px;
  }

  .rhtu__img {
    width: 130px;
    height: 100px;
    margin-bottom: 15px;
  }
  .errors__container {
    margin: 10px 0;
    height: 16px;
  }

  .info__text {
    margin-top: 25px;
    margin-bottom: 50px;
    padding: 0 40px;
  }
}

@media screen and (max-width: 730px){
  .mobile__container {
    max-width: 500px;
  }

  .left__inputs {
    width: 300px;
  }

  .inputs__error {
    max-width: 280px;
    font-size: 14px;
    line-height: 16px;
  }

  .agree_btn {
    width: 260px;
  }

  .cancel_btn {
    margin-left: 0;
    width: 260px;
  }

  .technic_work__container {
    padding: 0 30px;
    margin: 50px auto;
  }

  .technic_work__title {
    font-size: 24px;
  }

  .technic_work__subtitle {
    font-size: 15px;
  }
}
</style>
