const ACCOUNT_HOST = process.env.VUE_APP_API_HOST + process.env.VUE_APP_ACCOUNTS_PREFIX.replace('/', '')
const ACCOUNTS_INSTANCE = String(process.env.VUE_APP_ACCOUNTS_PREFIX).replace('/', '')
const PASSWORD_PATTERN = /^(?=.*[0-9])(?=.*[a-z])(?=.*[A-Z])(?=.*[!#%&*+\-.:<=>?@^_|~])[0-9a-zA-Z!#%&*+\-.:<=>?@^_|~]{12,255}$/
const PASSWORD_PATTERN_MSG = "Пароль должен содержать не менее 12 символов, минимум 1 букву нижнего, верхнего регистров (А - Z, a - z), цифру (от 0 до 9), а также специальные символы (!#%&*+-.:<=>?@^_|~) и не должен совпадать с пятью предыдущими"
const EIOS_LINK = process.env.VUE_APP_EIOS_LINK
const MESSAGE_LINK = process.env.VUE_APP_MSG_LINK

export {
  ACCOUNT_HOST,
  ACCOUNTS_INSTANCE,
  PASSWORD_PATTERN,
  EIOS_LINK,
  MESSAGE_LINK,
  PASSWORD_PATTERN_MSG
}
