<template>
  <v-app>
    <v-main
      id="base-content"
    >
      <div
        class="overflow-y-auto"
        style="height: var(--doc-height);"
      >
        <router-view />
      </div>
    </v-main>
  </v-app>
</template>

<script>
import { mapState } from 'vuex'

export default {
  name: 'App',
  components: {},
  data: () => ({
    surls: {},
  }),
  computed: {
    ...mapState({
      user: state => state.user,
      urls: state => state.urls,
    }),
  },
  watch: {
    urls () {
      this.surls = this.urls.urls
    }
  },
  mounted () {
    window.addEventListener("resize", this.documentHeight)
    this.documentHeight()
  },
  unmounted () {
    window.removeEventListener("resize", this.documentHeight)
  },
  methods: {
    documentHeight () {
      const doc = document.documentElement
      doc.style.setProperty('--doc-height', `${window.innerHeight}px`)
    }
  },
}
</script>

<style>
#app {
  font-family: 'Avenir', Helvetica, Arial, sans-serif;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
  text-align: center;
  /*color: #2cc30e50;*/
  /*height: 100%;*/
}

:root {
  --doc-height: 100vh;
}
</style>
