import { sendGetRequest, sendPostRequest, sendPutRequest } from '@/helper/requests'
const HOST_ACCOUNT = process.env.VUE_APP_API_HOST + process.env.VUE_APP_ACCOUNTS_PREFIX;
import authenticate from '@/urls/authenticate'
import user from '@/urls/user'
import {useCookies} from "vue3-cookies";
const {cookies} = useCookies();

function get_domain () {
  /*
    *
     */
  if (process.env.VUE_APP_COOKIE_DOMAIN !== undefined) {
    return process.env.VUE_APP_COOKIE_DOMAIN
  } else {
    return null
  }
}

export function clear_stores () {
  /*
    * Очистка всех используемых стораджей относящихся непосредсвтенно к браузеру.
    * На данный момент это localstorage и cookie
    */
  localStorage.removeItem('user-token') // clear your user's token from localstorage
  localStorage.removeItem('username')
  localStorage.removeItem('user-uid')
  let domain = get_domain()
  cookies.remove('userMuctr', null, domain)
}

function set_local_store (token, username, uid) {
  /*
    * Заполняем локальное хранилище
     */
  localStorage.setItem('user-token', token)
  localStorage.setItem('username', username)
  localStorage.setItem('user-uid', uid)
}

function set_cookie_store (token, username, uid) {
  /*
    * Заполняем кукисы
     */
  cookies.remove('userMuctr', null, get_domain()) // удалим старый
  cookies.set(
    'userMuctr',
    {
      userToken: token,
      userName: username,
      userUid: uid
    },
    '3y',
    null,
    get_domain(),
    null
  )
}

function set_stores (token, username, uid) {
  /*
    * Сохранения в стораджи браузера нужных параметров, для того что бы они сохранялись от входа до входа
     */
  set_cookie_store(token, username, uid)
  set_local_store(token, username, uid)
}

const state = {
  token_alive: false,
  uid: null,
  token: null,
  status: null,
  error: null,
  username: null,
  last_check: null,
  need_change_password: false,
  loading: false
}

const getters = {
  getToken: state => state.token,
  // eslint-disable-next-line no-unused-vars
  hasCookie: state => () => {
    return cookies.isKey('userMuctr')
  },
  isTokenAlive: state => state.token_alive,
  isCookieLoaded: state => !!state.token,
  isAuthenticated: state => state.token !== null,
  isNeedChangePassword: state => state.need_change_password,
  isCheckExpired: state => () => {
    return (new Date() - state.last_check) > (60 * 1000)
  }
}

const actions = {
  LOAD_FROM_COOKIE: ({ commit }) => {
    let userMUCTR = cookies.get('userMuctr')
    if (userMUCTR !== undefined && userMUCTR !== null) {
      commit('SET_TOKEN', userMUCTR.userToken)
      commit('SET_USERNAME', userMUCTR.userName)
      commit('SET_UID', userMUCTR.userUid)
    }
  },
  AUTH_REQUEST: ({commit}, {user, finalizer = () => {}, catcher = () => {} }) => {
    commit('AUTH_REQUEST');
    commit('SET_LOADING');
    let url = authenticate.AUTHENTICATE.AUTHENTICATION.LOGIN()
    sendPostRequest(
      url,
      {
        username: user.username,
        password: user.password
      },
      (data) => {
        set_stores(data.token, user.username, data.uid)
        commit('AUTH_SUCCESS', {token: data.token, username: user.username, uid: data.uid});
        commit('SET_NEED_CHANGE_PASSWORD', data.need_change_password);
        commit('SET_CHECK_TIME');
        commit('SET_NOT_LOADING');
        finalizer()
      },
      (err) => {
        clear_stores()
        commit('AUTH_ERROR', err.error);
        commit('SET_NOT_LOADING');
        catcher()
      }
    )
  },
  AUTH_LOGOUT: ({ commit }, {finalizer = () => {}, catcher = () => {}}) => {
    let url = authenticate.AUTHENTICATE.AUTHENTICATION.LOGOUT()
    sendPostRequest(
      url,
      {},
      () => {
        commit('AUTH_LOGOUT')
        clear_stores()
        finalizer()
      },
      (err) => {
        clear_stores()
        commit('AUTH_ERROR', err.error);
        commit('SET_NOT_LOADING');
        catcher()
      }
    )
  },
  SENT_EMAIL_FORGET_PASSWORD: ({commit}, {data, finalizer = () => {}, catcher = () => {}}) => {
    commit('SET_LOADING');
    let url = user.USERS.USER.PROFILE.PASSWORD.FORGET()
    sendPostRequest(
      url,
      {
        email: data.email
      },
      () => {
        commit('SET_NOT_LOADING');
        finalizer()
      },
      () => {
        commit('SET_NOT_LOADING');
        catcher()
      }
    )
  },
  CHANGE_FORGET_PASSWORD: ({commit}, {data, finalizer = () => {}, catcher = () => {}}) => {
    commit('SET_LOADING');
    let url = user.USERS.USER.PROFILE.PASSWORD.CHANGE()
    sendPostRequest(
      url,
      {
        token: data.token,
        // password: data.password
      },
      () => {
        commit('SET_NOT_LOADING');
        finalizer()
      },
      (err) => {
        catcher(err)
        commit('SET_NOT_LOADING');
      }
    )
  },
  CHANGE_PASSWORD_NEEDED: ({commit}, {data, finalizer = () => {}, catcher = () => {}}) => {
    commit('SET_LOADING')
    let url = user.USERS.USER.PROFILE.PASSWORD.NEED_CHANGE.CHANGE()
    sendPutRequest(
      url,
      {
        old_password: data.old_password,
        new_password: data.new_password,
        repeat: data.repeat,
        email: data.email
      },
      () => {
        commit('SET_NOT_LOADING')
        finalizer()
      },
      (err) => {
        commit('SET_NOT_LOADING')
        catcher(err)
      }
    )
  },
  GET_NEED_CHANGE_PASSWORD: ({commit}, { finalizer = () => {}, catcher = () => {}}) => {
    commit('SET_LOADING')
    let url = user.USERS.USER.PROFILE.PASSWORD.NEED_CHANGE.IS_NEED()
    sendGetRequest(
      url,
      () => {
        commit('SET_NOT_LOADING')
        finalizer()
      },
      (err) => {
        commit('SET_NOT_LOADING')
        catcher(err)
      }
    )
  },
  CHECK_TOKEN_ALIVE: ({commit, dispatch, getters}) => {
    commit('SET_LOADING')
    return new Promise((resolve, reject) => {
      if(getters.hasCookie){
        if(!getters.isCookieLoaded){
          dispatch('LOAD_FROM_COOKIE');
        }
        fetch(
          `${HOST_ACCOUNT}authenticate/token/alive/`,
          {
            headers: {
              'Content-Type': 'application/json',
            },
            body: JSON.stringify({token: getters.getToken}),
            method: 'POST'
          }
        ).then(resp => {
          if(!resp.ok) {
            clear_stores();
            commit('SET_NOT_LOADING');
            reject(Error(resp.statusText))
          }else {
            return resp.json()
          }
        }).then(json => {
          commit('SET_TOKEN_ALIVE', json.alive)
          if (json.alive === false) {
            clear_stores()
            commit('AUTH_LOGOUT').then(() => {
              reject(json);
            });
          } else {
            commit('SET_NEED_CHANGE_PASSWORD', json.need_change_password);
            commit('SET_CHECK_TIME');
            commit('SET_NOT_LOADING');
            resolve();
          }
        }).catch(error => {
          clear_stores();
          commit('AUTH_LOGOUT')
          commit('SET_NOT_LOADING');
          reject(error);
        });
      }else{
        commit('SET_NOT_LOADING');
        reject();
      }
    })
  },
  checkUserConfirmation: ({ commit, dispatch }, { data, finalizer = () => {}, catcher = () => {} }) => {
    const url = `${process.env.VUE_APP_API_HOST + process.env.VUE_APP_CONFIRMATION_PREFIX}user/document/need/acquaintance/`
    sendGetRequest(
      url, (data, url) => {
        finalizer(data, url)
      }, (data, url) => {
        catcher(data, url)
      }
    )
  },
}

const mutations = {
  AUTH_LOGOUT: (state) => {
    state.error = null
    state.token = null
    state.uid = null
    state.username = null
    state.last_check = null
    state.need_change_password = false
  },
  AUTH_REQUEST: (state) => {
    state.error = ''
  },
  AUTH_SUCCESS: (state, {token, username, uid}) => {
    state.token = token;
    state.username = username;
    state.uid = uid;
  },
  AUTH_ERROR: (state, error) => {
    state.error = error
  },
  SET_CHECK_TIME: (state) => {
    state.last_check = new Date()
  },
  CLEAR_CHECK_TIME: (state) => {
    state.last_check = null
  },
  SET_NEED_CHANGE_PASSWORD: (state, need_change) => {
    state.need_change_password = need_change
  },
  CLEAR_ERROR: (state) => {
    state.status = ''
    state.error = ''
  },
  SET_TOKEN: (state, token) => {
    state.token = token
    localStorage.setItem('user-token', token)
  },
  SET_TOKEN_ALIVE: (state, alive) => {
    state.token_alive = alive
  },
  SET_USERNAME: (state, username) => {
    state.username = username
    localStorage.setItem('username', username)
  },
  SET_UID: (state, uid) => {
    state.uid = uid
    localStorage.setItem('user-uid', uid)
  },
  SET_LOADING: (state) => {
    state.loading = true
  },
  SET_NOT_LOADING: (state) => {
    state.loading = false
  }
}

export default {
  namespaced: true,
  state,
  getters,
  actions,
  mutations
}
