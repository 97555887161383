import helper from './helper'

let getAccountsUrlWithParameters = helper.getAccountsUrlWithParameters

export default {
  getAccountsUrlWithParameters,
  USERS: {
    USER: {
      PROFILE: {
        PASSWORD: {
          FORGET:
           (gets = null) => getAccountsUrlWithParameters(
             'users|user|profile|password|forget',
             'post',
             gets
           ),
          CHANGE:
            (gets = null) => getAccountsUrlWithParameters(
              'users|user|profile|password|forget|change',
              'post',
              gets
            ),
          NEED_CHANGE: {
            CHANGE:
              (gets = null) => getAccountsUrlWithParameters(
                'users|user|profile|password|need|change',
                'put',
                gets
              ),
            IS_NEED:
              (gets = null) => getAccountsUrlWithParameters(
                'users|user|profile|password|need|change',
                'get',
                gets
              )
          }
        }
      }
    }
  }
}
