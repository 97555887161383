import {ACCOUNTS_INSTANCE} from "@/helper/consts";
import names from '@/modules/password/routers/names'
import ApprovePassword from '@/modules/password/recovery/ApprovePassword'
import NeedChangePassword from '@/modules/password/need_сhange/NeedChangePassword'
import RecoveryBreadcrumb from '@/modules/password/RecoveryBreadcrumb'


export default ([
  {
    path: '/recovery',
    name: names.MAIN,
    component: RecoveryBreadcrumb,
    meta: {
      requiredAuth: false,
      instances: [
        ACCOUNTS_INSTANCE
      ]
    },
    alias: '/recovery/approve',
    children: [
      {
        path: 'approve/:token([0-9a-zA-Z]+)',
        name: names.APPROVE.BASE,
        component: ApprovePassword,
        meta: {
          requiredAuth: false,
          instances: [
            ACCOUNTS_INSTANCE
          ]
        },
      },
    ]
  },
  {
    path: '/need_change_password',
    name: names.NEED_CHANGE_PASSWORD.BASE,
    component: NeedChangePassword,
    meta: {
      requiredAuth: true,
      instances: [
        ACCOUNTS_INSTANCE
      ]
    },
    children:[
    ]
  }
])
