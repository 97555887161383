<template>
  <v-container
    fluid
    class="pa-0 fill-height container_bg"
  >
    <v-row
      no-gutters
      align="center"
      justify="center"
      class="fill-height"
    >
      <v-col
        class="main__container"
      >
        <v-row
          no-gutters
        >
          <v-col
            cols="12"
            align="center"
          >
            <v-img
              :src="rhtuImg"
              aspect-ratio="1"
              alt="rhtu_logo"
              class="main__img"
            />
          </v-col>
          <v-col
            cols="12"
            align="center"
          >
            <div class="main__title">
              Изменение пароля <br>
              Корпоративной учетной записи РХТУ&nbsp;им.&nbsp;Д.И.&nbsp;Менделеева
            </div>
            <div
              v-if="!loading && alertType === 'success'"
              class="main__subtitle"
            >
              Уважаемый пользователь! <br>
              На Вашу почту было отправлено письмо с временным паролем. <br>
              Воспользуйтесь им для восстановления доступа к Корпоративной учетной записи РХТУ&nbsp;им.&nbsp;Д.И.&nbsp;Менделеева.
            </div>
            <semipolar-spinner
              v-if="loading"
              :animation-duration="2000"
              :size="$vuetify.display.mdAndUp ? 140 : 60"
              color="#356A9E"
            />
            <v-alert
              v-model="alert"
              :type="alertType"
              :icon="false"
              variant="text"
              class="text-h5"
            >
              {{ alertText }}
            </v-alert>
            <v-btn
              :loading="loading"
              class="send__btn"
              @click="$router.push({ name: names.MAIN })"
            >
              На главную
            </v-btn>
          </v-col>
        </v-row>
      </v-col>
    </v-row>
  </v-container>
</template>

<script>
import { mapActions } from 'vuex'
import names from '@/modules/core/routers/names'
import rhtuImg from "@/assets/rhtu.svg";
import {SemipolarSpinner} from "epic-spinners";

export default {
  name: 'ApprovePassword',
  components: { SemipolarSpinner },
  data: function () {
    return {
      rhtuImg: rhtuImg,
      show: false,
      names: names,
      alert: false,
      alertText: '',
      alertType: 'error',
      password: '',
      loading: false,
    }
  },
  methods: {
    ...mapActions({
      changeForgetPassword: 'user/CHANGE_FORGET_PASSWORD'
    }),
    changePassword () {
      this.alert = false
      this.loading = true
      let data = {
        token: this.$route.params.token,
      }
      this.changeForgetPassword({
        data,
        finalizer: () => {
          this.loading = false
          this.alert = true
          this.alertType = 'success'
          this.alertText = 'Скоро вы будете перенаправлены на страницу авторизации.'
          setTimeout(() => {
            this.$router.push({ name: names.MAIN })
          }, 8000)
        },
        catcher: (err) => {
          this.loading = false
          this.alert = true
          this.alertType = 'error'
          this.alertText = err.password[0]
        }
      })
    }
  },
  // eslint-disable-next-line vue/order-in-components
  created() {
    if (this.$route.params.token) {
      this.changePassword()
    }
  }
}
</script>

<style scoped lang="scss">
.container_bg {
  background: url("/src/assets/molecule_bg.png") no-repeat;
  background-size: cover;
  min-height: 900px;
}

.main__container {
  display: flex;
  justify-content: center;
  align-items: center;
  max-width: 822px;
  min-height: 842px;
  background: white;
  border-radius: 25px;
  z-index: 1;
}

.main__img {
  width: 198px;
  height: 152px;
  margin-bottom: 25px;
}

.main__title {
  margin-bottom: 25px;
  font-size: 36px;
  font-style: normal;
  font-weight: 700;
  line-height: normal;
  letter-spacing: 1.44px;
  color: #356A9E;
}

.main__subtitle {
  padding: 0 60px;
  margin-bottom: 20px;
  color: #7C7C7C;
  text-align: center;
  font-size: 18px;
  font-style: normal;
  font-weight: 400;
  line-height: normal;
}

.main__inputs {
  width: 420px;
}

.send__btn {
  margin-top: 30px;
  width: 200px;
  height: 60px;
  border-radius: 10px;
  background: #1D71B8 !important;
  box-shadow: 0 4px 4px 0 rgba(0, 0, 0, 0.25);
  color: #FFF;
  font-size: 18px;
  font-style: normal;
  font-weight: 700;
  line-height: normal;

  &--back {
    margin-left: 20px;
    font-size: 18px;
    font-style: normal;
    font-weight: 700;
    line-height: normal;
    width: 200px;
    height: 60px;
    border-radius: 10px;
    border: 1px solid #DADADA;
    color: #1D71B8;
    background: #FFF;
    box-shadow: 0 4px 4px 0 rgba(0, 0, 0, 0.25);
  }

  &:hover {
    background: #045396 !important;
    box-shadow: 0 5px 5px 0 rgba(0, 0, 0, 0.5);
  }

  &:active {
    background: #045396 !important;
  }
}

@media screen and (max-width: 730px){
  .main__container {
    min-height: 872px;
  }

  .main__img {
    width: 158px;
    height: 122px;
    margin-bottom: 30px;
  }

  .main__title {
    font-size: 30px;
  }

  .main__subtitle {
    margin-bottom: 10px;
  }

  .main__inputs {
    width: 300px;
  }

  .send__btn {
    margin: 0 50px;
    width: 300px;

    &--back {
      margin: 30px 50px;
      width: 300px;
    }
  }
}

@media screen and (max-width: 500px){
  .container_bg {
    min-height: 960px;
  }

  .main__container {
    min-height: 932px;
  }

  .main__img {
    width: 138px;
    height: 102px;
  }

  .main__title {
    font-size: 24px;
  }

  .main__subtitle {
    padding: 0 20px;
  }

  .send__btn {
    margin: 0;

    &--back {
      margin: 30px 0;
    }
  }
}
</style>

