const password = {
  BASE: 'recovery',
  MAIN: undefined,
  APPROVE: {
    BASE: undefined,
  },
  NEED_CHANGE_PASSWORD: {
    BASE: undefined,
  }
};
password.MAIN = `${password.BASE}|Recovery`
password.APPROVE.BASE = `${password.BASE}|Approve`
password.NEED_CHANGE_PASSWORD.BASE  = `${password.BASE}|NeedChangePassword`
export default {
  ...password
}
