import {
    ACCOUNT_HOST,
    ACCOUNTS_INSTANCE,

} from "@/helper/consts";
import { getUrlWithParameters } from "@/helper/urls";


export default {
    getAccountsUrlWithParameters: function (name, method, parameters, gets) {
        return getUrlWithParameters(
            ACCOUNT_HOST, ACCOUNTS_INSTANCE, name, method, parameters, gets
        );
    },
}
