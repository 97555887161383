import { createStore } from 'vuex'
import user from './modules/user'
import urls from './modules/urls'
import oauth from './modules/oauth'


export default createStore({
  state: {
  },
  getters: {
  },
  mutations: {
  },
  actions: {
  },
  modules: {
    user,
    urls,
    oauth
  }
})
