import { createApp } from 'vue'

import App from './App'
import router from './router'
import vuetify from './plugins/vuetify';
import store from './store/index'
import '../public/css/main.css'
import '../public/css/custom.scss'
import VueCookie from 'vue3-cookies';
import * as Sentry from "@sentry/vue";

const app = createApp({})

app.config.productionTip = false;
Sentry.init({
  app,
  dsn: "https://7d7cc0914637fe4a858b9dbcc23126dd@sentry.muctr.ru/10",
  integrations: [
    Sentry.browserTracingIntegration(),
  ],
  // Performance Monitoring
  tracesSampleRate: 1.0, //  Capture 100% of the transactions
  // Set 'tracePropagationTargets' to control for which URLs distributed tracing should be enabled
});

createApp(App)
  .use(router)
  .use(store)
  .use(vuetify)
  .use(VueCookie)
  .mount('#app')
