<template>
  <!--  Принудительная смена пароля, происходит при логировании, если бэк говорит что нужно сменить пароль-->
  <v-container
    fluid
    class="pa-0 fill-height container_bg"
  >
    <v-row
      no-gutters
      align="center"
      justify="center"
      class="fill-height"
    >
      <v-col
        class="main__container"
      >
        <v-row
          no-gutters
        >
          <v-col
            cols="12"
            align="center"
          >
            <v-img
              :src="rhtuImg"
              aspect-ratio="1"
              alt="rhtu_logo"
              class="main__img"
            />
          </v-col>
          <v-col
            cols="12"
            align="center"
          >
            <div class="main__title">
              Необходимо обязательно сменить предыдущий пароль
            </div>
            <v-alert
              v-show="need_set_email"
              type="warning"
              :value="true"
              :icon="false"
              variant="text"
              class="text-xs-left"
            >
              Уважаемый пользователь!
              К сожалению, у Вашего аккаунта отсутствует актуальная внешняя почта, необходимая для корректной
              работы функционала по восстановлению пароля от Корпоративной учетной записи РХТУ им. Д.И. Менделеева.
              Пожалуйста, укажите актуальный адрес Вашей личной электронной почты (Yandex, Mail, Gmail и др.),
              на которую мы направим ссылку для подтверждения регистрации.
            </v-alert>
            <div
              v-if="!is_redirecting && !is_not_need"
              class="main__subtitle"
            >
              Выберите надежный пароль и не используйте его для других аккаунтов
            </div>
          </v-col>
          <v-col
            v-if="!is_redirecting && !is_not_need"
            cols="12"
            align="center"
          >
            <v-alert
              v-if="user.error"
              :value="true"
              :icon="false"
              variant="text"
              type="error"
              class="rounded-lg"
            >
              {{ user.error }}
            </v-alert>
            <v-form
              ref="form"
              v-model="valid"
              class="main__inputs"
            >
              <v-text-field
                v-if="need_set_email"
                ref="email"
                v-model="value.email"
                :rules="[rules.required ,rules.email, rules.noMuctr, rules.noNirhtu]"
                :error="hasError('email')"
                :error-messages="getError('email')"
                label="Внешняя почта"
                placeholder="Внешняя почта для восстановления"
                title="Почта будет использоваться для восстановления пароля и системных оповещений."
                required
                class="py-1 mb-2"
                variant="outlined"
                :loading="loading"
                type="text"
                @click:appendInner="show= !show"
              />
              <v-text-field
                ref="password"
                v-model="value.old_password"
                class="py-1 mb-2"
                :rules="[rules.required]"
                :error="hasError('old_password')"
                :error-messages="getError('old_password')"
                label="Введите временный/старый пароль"
                placeholder="Пароль"
                required
                variant="outlined"
                color="primary"
                :loading="loading"
                :title="PASSWORD_TEXT"
                :type="show ? 'text' : 'password'"
                :append-inner-icon="show ? 'visibility_off' : 'visibility'"
                @click:appendInner="show= !show"
              />
              <v-text-field
                ref="password"
                v-model="value.new_password"
                class="py-1 mb-2"
                :rules="[rules.required ,rules.passStr]"
                :error="hasError('password')"
                :error-messages="getError('password')"
                label="Новый пароль пользователя"
                placeholder="Пароль"
                required
                variant="outlined"
                color="primary"
                :loading="loading"
                :title="PASSWORD_TEXT"
                :type="show ? 'text' : 'password'"
                :append-inner-icon="show ? 'visibility_off' : 'visibility'"
                @click:appendInner="show= !show"
              />
              <v-text-field
                ref="password"
                v-model="value.repeat"
                class="py-1"
                :rules="[rules.required,rules.passStr, rules.identif]"
                :error="hasError('repeat')"
                :error-messages="getError('repeat')"
                label="Повторите пароль"
                placeholder="Пароль"
                required
                variant="outlined"
                color="primary"
                :loading="loading"
                :title="PASSWORD_TEXT"
                :type="show ? 'text' : 'password'"
                :append-inner-icon="show ? 'visibility_off' : 'visibility'"
                @click:appendInner="show= !show"
              />
            </v-form>
            <v-expand-transition>
              <v-alert
                v-show="show_disclaimer"
                type="info"
                variant="outlined"
                :value="true"
                class="text-xs-left mb-4 rounded-lg"
              >
                Более подробную информацию Вы можете найти по ссылкам:<br>
                <li>
                  <a
                    class="text-info"
                    href="https://wiki.muctr.ru/pages/viewpage.action?pageId=62095496"
                  >Инструкция по использованию Корпоративной учетной записи РХТУ им. Д.И. Менделеева</a>
                </li>
                <li>
                  <a
                    class="text-info"
                    href="https://wiki.muctr.ru/pages/viewpage.action?pageId=62095499"
                  >Инструкция по использованию корпоративной электронной почты</a>
                </li>
                Если у вас возникли трудности с использованием системы, вопросы или предложения, обратитесь в Единый центр поддержки пользователей по почте <a
                  class="text-info"
                  href="mailto:support@muctr.ru"
                >support@muctr.ru</a>.<br>
              </v-alert>
            </v-expand-transition>
            <v-btn
              :disabled="!valid"
              :loading="loading"
              class="send__btn"
              @click="changePassword"
            >
              Сменить
            </v-btn>
          </v-col>
          <v-col
            v-if="is_redirecting"
            class="px-5 px-md-10"
          >
            <v-alert
              :value="true"
              type="success"
              class="my-0"
              :icon="false"
              variant="text"
            >
              Вы успешно поменяли пароль, вскоре вы будете перенаправлены далее и сможете продолжить работу в системе.
            </v-alert>
            <v-progress-linear
              indeterminate
              class="my-1 rounded-lg bg-green"
            />
          </v-col>
          <v-col
            v-if="is_not_need"
            class="px-5 px-md-10"
          >
            <v-alert
              :value="true"
              type="info"
              :icon="false"
              variant="text"
              class="mb-0"
            >
              Смена пароля не требуется, либо вы уже сменили его при помощи любого из других путей, приносим извинения за неудобства,
              вскоре вы будете перенаправлены далее и сможете продолжить работу в системе.
            </v-alert>
            <v-progress-linear
              indeterminate
              color="primary"
              class="mt-0"
            />
          </v-col>
        </v-row>
      </v-col>
    </v-row>
  </v-container>
</template>

<script>
import { mapActions, mapGetters, mapState } from 'vuex'
  import {PASSWORD_PATTERN} from "@/helper/consts";
import { goToPortalPage } from '@/helper'
  import names from '@/modules/core/routers/names'
  import FormErrorsMixin from '@/mixins/FormErrorsMixin';
import rhtuImg from "@/assets/rhtu.svg";

  export default {
    name: 'NeedChangePassword',
    mixins: [FormErrorsMixin],
    data: function () {
      return {
        PASSWORD_TEXT: [
          "Пароль должен содержать не менее 12 символов, минимум 1 букву нижнего, верхнего регистров (А - Z, a - z), цифру (от 0 до 9), а также специальные символы (!#%&*+-.:<=>?@^_|~) и не должен совпадать с пятью предыдущими"
        ].join(''),
        PASSWORD_SYMBOLS: '(!#%&\'*+-.:<=>?@^_`|~)',
        rules: {
          required: value => !!value || 'Пустое поле',
          identif: value => {
            return ((value === this.value.repeat) && (value === this.value.new_password)) || 'Пароли не совпадают'
          },
          passStr: value => {
            // const pattern = /^(?=.*\d)(?=.*[a-z])(?=.*[A-Z])(?=.*[!#&*+-.:<=>?@^_|~%])[0-9a-zA-Z!#&*+-.:<=>?@^_|~%]{12,}$/
            return PASSWORD_PATTERN.test(value) || this.PASSWORD_TEXT
          },
          email: value => {
            const pattern = /^(([^<>()[\]\\.,;:\s@"]+(\.[^<>()[\]\\.,;:\s@"]+)*)|(".+"))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,}))$/
            return pattern.test(value) || 'Неправильный формат электронной почты.'
          },
          noMuctr: value => {
            const pattern = /^(?!.*@muctr\.ru).*$/
            return pattern.test(value) || 'Почта в домене @muctr.ru не допустима.'
          },
          noNirhtu: value => {
            const pattern = /^(?!.*@nirhtu\.ru).*$/
            return pattern.test(value) || 'Почта в домене @nirhtu.ru не допустима.'
          },
        },
        rhtuImg: rhtuImg,
        show: false,
        names: names,
        valid: false,
        loading: false,
        is_redirecting: false,
        is_not_need: false,
        addition_info: false,
        usernames: {
          old: '',
          new: '',
        },
        show_disclaimer: false,
        need_set_email: false,
        value: {
          repeat: '',
          new_password: '',
          old_password: '',
        },
      }
    },
    watch: {
      value: {
        handler: function () {
          this.errors = {
            fields: {},
            non: []
          }
          this.error = null
        },
        deep: true
      }
    },
    computed: {
      ...mapState({
        user: state => state.user,
      }),
      ...mapGetters({
        'isNeedChangePassword': 'user/isNeedChangePassword',
      }),
      from (){
        // eslint-disable-next-line no-prototype-builtins
        if (this.$route.query.hasOwnProperty('from'))
          return this.$route.query.from;
        else
          return null
      },
      finalizer (){
        // eslint-disable-next-line no-prototype-builtins
        if(this.$route.query.hasOwnProperty('finalizer'))
          return this.$route.query.finalizer;
        else
          return null
      }
    },
    created() {
      // this.$store.commit('maintoolbar/setStartLoading');
      // isAliveToken(
      //   () => {
      //     this.loading = false;
      //     this.$store.commit('maintoolbar/setEndRLoading');
      //     if(!this.$store.getters['user/isNeedChangePassword'])
      //       this.redirectWhenEnd()
      //     else
      //       this.getNormalizerInfo()
      //   },
      //   () => {
      //     this.$store.commit('maintoolbar/setEndRLoading');
      //     this.$router.push({
      //       name: names.MAIN,
      //       query: this.$route.query
      //     })
      //   }
      // )
      this.getNormalizerInfo()
    },

    methods: {
      ...mapActions({
        changePasswordNeeded: 'user/CHANGE_PASSWORD_NEEDED',
        getNeedChangePassword: 'user/GET_NEED_CHANGE_PASSWORD'
      }),
      redirectWhenEnd() {
        if(this.from !== null){
          document.location.href = this.from;
        } else if (this.$route.query.redirect === undefined) {
          goToPortalPage()
        } else {
          this.$router.push({path: this.$route.query.redirect});
        }
      },
      getNormalizerInfo() {
        this.loading = true;
        this.getNeedChangePassword({
          finalizer: (data) => {
            this.loading = false;
            this.usernames.old = data.old;
            this.usernames.new = data.new;
            this.show_disclaimer = data.showed;
            this.need_set_email = data.need_set_email;
          },
          catcher: () => {
            this.loading = false;
          }
        })
      },
      changePassword () {
        if(this.valid) {
          this.loading = true
          let data = this.value
          this.changePasswordNeeded({
            data,
            finalizer: () => {
              this.loading = false;
              this.$store.commit("user/CLEAR_CHECK_TIME");
              this.is_redirecting = true;
              this.redirectWhenEnd();
            },
            catcher: (error) => {
              this.mapErrors(error)
              this.$store.commit("user/CLEAR_CHECK_TIME");
              this.is_redirecting = false;
              if (error.detail) {
                this.is_not_need = error.detail[0] === "Смена пароля не требуется" ?? false;
              }
              this.loading = false;
              // this.redirectWhenEnd()
            },
          })
        }
      }
    }
  }
</script>

<!-- Add "scoped" attribute to limit CSS to this component only -->
<style scoped lang="scss">
.container_bg {
  background: url("/src/assets/molecule_bg.png") no-repeat;
  background-size: cover;
  min-height: 900px;
}

.main__container {
  display: flex;
  justify-content: center;
  align-items: center;
  max-width: 822px;
  min-height: 842px;
  background: white;
  border-radius: 25px;
  z-index: 1;
}

.main__img {
  width: 198px;
  height: 152px;
  margin-bottom: 25px;
}

.main__title {
  margin-bottom: 25px;
  font-size: 36px;
  font-style: normal;
  font-weight: 700;
  line-height: normal;
  letter-spacing: 1.44px;
  color: #356A9E;
}

.main__subtitle {
  padding: 0 60px;
  margin-bottom: 20px;
  color: #7C7C7C;
  text-align: center;
  font-size: 18px;
  font-style: normal;
  font-weight: 400;
  line-height: normal;
}

.main__inputs {
  width: 420px;
}

.send__btn {
  margin-top: 10px;
  width: 200px;
  height: 60px;
  border-radius: 10px;
  background: #1D71B8 !important;
  box-shadow: 0 4px 4px 0 rgba(0, 0, 0, 0.25);
  color: #FFF;
  font-size: 18px;
  font-style: normal;
  font-weight: 700;
  line-height: normal;

  &--back {
    margin-left: 20px;
    font-size: 18px;
    font-style: normal;
    font-weight: 700;
    line-height: normal;
    width: 200px;
    height: 60px;
    border-radius: 10px;
    border: 1px solid #DADADA;
    color: #1D71B8;
    background: #FFF;
    box-shadow: 0 4px 4px 0 rgba(0, 0, 0, 0.25);
  }

  &:hover {
    background: #045396 !important;
    box-shadow: 0 5px 5px 0 rgba(0, 0, 0, 0.5);
  }

  &:active {
    background: #045396 !important;
  }
}

@media screen and (max-width: 730px){
  .main__container {
    min-height: 872px;
  }

  .main__img {
    width: 158px;
    height: 122px;
    margin-bottom: 30px;
  }

  .main__title {
    font-size: 30px;
  }

  .main__subtitle {
    margin-bottom: 10px;
  }

  .main__inputs {
    width: 300px;
  }

  .send__btn {
    margin: 0 50px;
    width: 300px;

    &--back {
      margin: 30px 50px;
      width: 300px;
    }
  }
}

@media screen and (max-width: 500px){
  .container_bg {
    min-height: 960px;
  }

  .main__container {
    min-height: 932px;
  }

  .main__img {
    width: 138px;
    height: 102px;
  }

  .main__title {
    font-size: 24px;
  }

  .main__subtitle {
    padding: 0 20px;
  }

  .send__btn {
    margin: 0;

    &--back {
      margin: 30px 0;
    }
  }
}
</style>
