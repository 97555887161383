import PageNotFound from '@/modules/core/pages/PageNotFound'
import coreRouter from '@/modules/core/routers/routers'
import recoveryRouter from '@/modules/password/routers/routers'
import oauthRouter from '@/modules/oauth/routers/routers'

import { authRequiredHook, instanceRequiredHook } from '@/router/hooks'
import { createRouter, createWebHistory } from 'vue-router'

const baseRoutes = [
  {
    path: '/:pathMatch(.*)*',
    name: 'PageNotFound',
    component: PageNotFound
  },
];
let allRoutes = []

allRoutes = allRoutes.concat(
  baseRoutes,
  coreRouter,
  recoveryRouter,
  oauthRouter
);

const router = createRouter(
  {
    base: '',
    history: createWebHistory(process.env.BASE_URL),
    routes: allRoutes,
  }
  )

router.beforeEach(authRequiredHook);
router.beforeEach(instanceRequiredHook);

export default router;
