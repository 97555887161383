<template>
  <v-container
    fluid
    class="pa-0 fill-height container_bg"
  >
    <v-row
      no-gutters
      align="center"
      justify="center"
      class="fill-height"
    >
      <v-col
        class="main__container"
      >
        <v-row
          no-gutters
        >
          <v-col
            cols="12"
            align="center"
          >
            <v-img
              :src="rhtuImg"
              aspect-ratio="1"
              alt="rhtu_logo"
              class="main__img"
            />
          </v-col>
          <v-col
            cols="12"
            align="center"
          >
            <div class="main__title">
              Восстановление пароля
            </div>
            <v-alert
              v-if="alert"
              v-model="alert"
              type="success"
              :icon="false"
              variant="text"
              class="ma-4 text-black text-h6"
            >
              На вашу почту выслано письмо для подтверждения
            </v-alert>
            <div
              v-else
              class="main__subtitle"
            >
              Введите адрес вашей личной электронной почты, с помощью которой вы регистрировались, и мы отправим вам ссылку для сброса пароля
            </div>
          </v-col>
          <v-col
            cols="12"
            align="center"
          >
            <v-form
              v-model="valid"
              class="main__inputs"
            >
              <v-text-field
                v-model="value.email"
                :rules="[rules.required, rules.email, rules.noMuctr]"
                label="Почта"
                class="mb-2"
                :loading="loading"
                variant="outlined"
                color="primary"
              />
            </v-form>
            <v-btn
              :loading="loading"
              :disabled="!valid"
              class="send__btn"
              @click="sentForgetPass"
            >
              Восстановить
            </v-btn>
            <v-btn
              :to="{name: namesAuth.MAIN}"
              class="send__btn--back"
            >
              Назад
            </v-btn>
          </v-col>
        </v-row>
      </v-col>
    </v-row>
  </v-container>
</template>

<script>
import namesAuth from '@/modules/core/routers/names'
import namesRecovery from '@/modules/password/routers/names'
import { mapActions } from 'vuex'
import rhtuImg from "@/assets/rhtu.svg";

export default {
  name: 'RecoveryPassword',
  data: function () {
    return {
      rhtuImg: rhtuImg,
      rules: {
        required: value => !!value || 'Пустое поле',
        email: value => {
          const pattern = /^(([^<>()[\]\\.,;:\s@"]+(\.[^<>()[\]\\.,;:\s@"]+)*)|(".+"))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,}))$/
          return pattern.test(value) || 'Неккоректный почтовый ящик'
        },
        noMuctr: value => {
          const pattern = /^(?!.*@muctr.ru).*$/
          return pattern.test(value) || 'Почта в домене @muctr.ru не допустима'
        },
      },
      alert: false,
      valid: false,
      value: {
        email: '',
      },
      namesAuth: namesAuth,
      namesRecovery: namesRecovery,
      loading: false,

    }
  },
  methods: {
    ...mapActions({
      sentEmailForgetPassword: "user/SENT_EMAIL_FORGET_PASSWORD"
    }),
    sentForgetPass() {
      if(this.valid) {
        this.loading = true;
        let data = this.value
        this.sentEmailForgetPassword({
          data,
          finalizer: () => {
            this.alert = true;
            setTimeout(() => {
              this.$router.push({name: namesAuth.MAIN});
              this.loading = false;
            }, 5000);
          },
          catcher: () => {
            this.loading = false
          }
        })
      }
    }
  },
}
</script>

<style scoped lang="scss">
.container_bg {
  background: url("/src/assets/molecule_bg.png") no-repeat;
  background-size: cover;
  min-height: 700px;
}

.main__container {
  display: flex;
  justify-content: center;
  align-items: center;
  max-width: 722px;
  min-height: 642px;
  background: white;
  border-radius: 25px;
  z-index: 1;
}

.main__img {
  width: 198px;
  height: 152px;
  margin-bottom: 25px;
}

.main__title {
  margin-bottom: 25px;
  font-size: 36px;
  font-style: normal;
  font-weight: 700;
  line-height: normal;
  letter-spacing: 1.44px;
  color: #356A9E;
}

.main__subtitle {
  padding: 0 60px;
  margin-bottom: 50px;
  color: #7C7C7C;
  text-align: center;
  font-size: 18px;
  font-style: normal;
  font-weight: 400;
  line-height: normal;
}

.main__inputs {
  width: 420px;
}

.send__btn {
  width: 200px;
  height: 60px;
  border-radius: 10px;
  background: #1D71B8 !important;
  box-shadow: 0 4px 4px 0 rgba(0, 0, 0, 0.25);
  color: #FFF;
  font-size: 18px;
  font-style: normal;
  font-weight: 700;
  line-height: normal;

  &--back {
    margin-left: 20px;
    font-size: 18px;
    font-style: normal;
    font-weight: 700;
    line-height: normal;
    width: 200px;
    height: 60px;
    border-radius: 10px;
    border: 1px solid #DADADA;
    color: #1D71B8;
    background: #FFF;
    box-shadow: 0 4px 4px 0 rgba(0, 0, 0, 0.25);
  }

  &:hover {
    background: #045396 !important;
    box-shadow: 0 5px 5px 0 rgba(0, 0, 0, 0.5);
  }

  &:active {
    background: #045396 !important;
  }
}

@media screen and (max-width: 730px){
  .main__container {
    min-height: 672px;
  }

  .main__img {
    width: 158px;
    height: 122px;
    margin-bottom: 30px;
  }

  .main__title {
    font-size: 30px;
  }

  .main__subtitle {
    margin-bottom: 30px;
  }

  .main__inputs {
    width: 300px;
  }

  .send__btn {
    margin: 0 50px;
    width: 300px;

    &--back {
      margin: 30px 50px;
      width: 300px;
    }
  }
}

@media screen and (max-width: 500px){
  .container_bg {
    min-height: 800px;
  }

  .main__container {
    min-height: 732px;
  }

  .main__img {
    width: 138px;
    height: 102px;
  }

  .main__title {
    font-size: 24px;
  }

  .main__subtitle {
    padding: 0 20px;
  }

  .send__btn {
    margin: 0;

    &--back {
      margin: 30px 0;
    }
  }
}
</style>
