<template>
  <v-container
    fluid
    class="pa-0"
    :class="$vuetify.display.mdAndDown ? 'mobile__bg' : 'fill-height'"
  >
    <v-row
      no-gutters
      align="center"
      :justify="$vuetify.display.mdAndDown ? 'center' : 'space-between'"
      :class="$vuetify.display.mdAndDown && $vuetify.display.width > 370 ? 'fill-height flex-column' : 'fill-height'"
    >
      <div
        v-if="$vuetify.display.mdAndDown"
        :class="$vuetify.display.mdAndDown ? 'mobile__container' : ''"
      >
        <p class="right__title">
          Добро пожаловать
        </p>
        <p class="right__subtitle">
          на корпоративный портал РХТУ&nbsp;им.&nbsp;Д.И.&nbsp;Менделеева!
        </p>
      </div>
      <v-col
        class="left__container fill-height"
        :class="$vuetify.display.mdAndDown ? 'mobile__container' : ''"
      >
        <v-row
          no-gutters
        >
          <v-col
            cols="12"
            align="center"
          >
            <v-img
              :src="rhtuImg"
              aspect-ratio="1"
              alt="rhtu_logo"
              class="left__img"
            />
          </v-col>
          <v-col
            v-if="!technic_work"
            cols="12"
            align="center"
          >
            <v-form
              v-model="valid"
              class="left__inputs"
            >
              <div class="errors__container">
                <v-alert
                  v-if="user.error"
                  :value="true"
                  class="inputs__error"
                  outline
                  :icon="false"
                  type="error"
                  variant="text"
                >
                  {{ user.error }}
                </v-alert>
              </div>
              <v-text-field
                v-model="username"
                color="#1D71B8"
                label="Логин"
                name="login"
                type="text"
                :rules="[rules.symbControl, rules.not_null]"
                class="mb-2"
                variant="outlined"
                :loading="loading"
              />
              <v-text-field
                id="password"
                v-model="password"
                :append-inner-icon="show ? 'visibility_off' : 'visibility'"
                :type="show ? 'text' : 'password'"
                :rules="[rules.required, rules.symbControl]"
                label="Пароль"
                :loading="loading"
                color="primary"
                name="password"
                variant="outlined"
                @click:appendInner="show = !show"
                @keyup.enter="login"
              />
            </v-form>
            <div class="recovery__text">
              <router-link
                :to="{ name: names.MAIN }"
              >
                Забыли пароль?
              </router-link>
            </div>
            <v-btn
              :disabled="!valid"
              :loading="loading"
              class="auth__btn"
              @click="login"
            >
              ВОЙТИ
            </v-btn>
            <div class="info__text">
              Если у вас возникли трудности с использованием системы, вопросы или предложения, обратитесь в Единый центр поддержки пользователей по почте
              <a
                href="mailto:support@muctr.ru"
                class="info__text--link"
              >support@muctr.ru</a>.
            </div>
          </v-col>
          <v-col
            v-else
            cols="12"
            align="center"
          >
            <div class="technic_work__container">
              <p class="technic_work__title">
                Ведутся технические работы
              </p>
              <p class="technic_work__subtitle">
                Извините за временные неудобства!
              </p>
            </div>
            <div class="info__text">
              Если у вас возникли трудности с использованием системы, вопросы или предложения, обратитесь в Единый центр
              поддержки пользователей по почте
              <a
                href="mailto:support@muctr.ru"
                class="info__text--link"
              >support@muctr.ru</a>.
            </div>
          </v-col>
        </v-row>
      </v-col>
      <v-col
        v-if="$vuetify.display.lgAndUp"
        class="right__container fill-height"
      >
        <div class="right__text">
          <p class="right__title">
            Добро пожаловать
          </p>
          <p class="right__subtitle">
            на корпоративный портал РХТУ&nbsp;им.&nbsp;Д.И.&nbsp;Менделеева!
          </p>
          <p class="right__welcome_text">
            Вход в систему возможен только для зарегистрированных пользователей личного кабинета.
          </p>
          <p class="right__info_text">
            <a
              href="https://wiki.muctr.ru/pages/viewpage.action?pageId=107978962"
              target="_blank"
              class="right__info_text--link"
            >Инструкция</a>
            по работе в Корпоративном портале РХТУ&nbsp;им.&nbsp;Д.И.&nbsp;Менделеева
          </p>
          <a
            v-if="priem_alert"
            class="abitur__btn"
            href="https://postupi.muctr.ru/"
          >
            Личный кабинет абитуриента
          </a>
        </div>
      </v-col>
      <div
        v-if="$vuetify.display.mdAndDown"
        :class="$vuetify.display.mdAndDown ? 'mobile__container' : ''"
      >
        <p class="right__welcome_text">
          Вход в систему возможен только для зарегистрированных пользователей личного кабинета.
        </p>
        <p class="right__info_text">
          <a
            href="https://wiki.muctr.ru/pages/viewpage.action?pageId=107978962"
            target="_blank"
            class="right__info_text--link"
          >Инструкция</a>
          по работе в Корпоративном портале РХТУ&nbsp;им.&nbsp;Д.И.&nbsp;Менделеева
        </p>
      </div>
    </v-row>
    <v-bottom-sheet
      v-model="sheet"
      inset
      persistent
      class="myqq"
    >
      <v-card class="pt-2 rounded-t-xl">
        <v-card-text class="text-justify mx-5 rounded-t-xl">
          Уважаемые посетители!<br v-if="$vuetify.display.mobile"> РХТУ им. Д. И. Менделеева обрабатывает файлы cookie с
          использованием сервиса «Яндекс Метрика» и встроенных сервисов аналитики. Продолжая работу на
          нашем сайте, вы подтверждаете свое согласие на использование cookies вашего браузера, которые помогают сделать
          наш сайт удобнее.
        </v-card-text>
        <v-card-actions class="mb-6">
          <v-row
            justify="center"
            no-gutters
          >
            <v-btn
              class="mx-3"
              variant="outlined"
              color="green"
              @click="sheet=false"
            >
              Я согласен
            </v-btn>
          </v-row>
        </v-card-actions>
      </v-card>
    </v-bottom-sheet>
  </v-container>
</template>

<script>
import {mapState, mapActions, mapGetters} from 'vuex'
import names from '@/modules/password/routers/names'
const {cookies} = useCookies();

import {
  goToPortalPage
} from '@/helper'
import Snowflakes from 'magic-snowflakes';
import rhtuImg from '@/assets/rhtu.svg'
import {useCookies} from "vue3-cookies";

export default {
  name: `Authentication`,
  components: {},
  data: function () {
    return {
      priem_alert: false,
      technic_work: false,
      loading: false,
      sheet: true,
      valid: false,
      username: null,
      password: null,
      show: false,
      msg: false,
      rhtuImg: rhtuImg,
      names: names,
      rules: {
        required: value => !!value || 'Введите пароль',
        notLogin: value =>  {
           let username = String(this.username).replace('@muctr.ru', '')
         return  value.includes(username)===false || 'Пароль не должен содержать в себе логин'
        },
        symbControl: value => this.symbControl(value) || 'Некорректный ввод',
        not_null: value => {
          if (value !== undefined && value !== null) {
            if (typeof value === "string") {
              return value.trim().length > 0 || 'Поле не может быть пустым'
            } else {
              return value || 'Поле не может быть пустым'
            }
          } else {
            return 'Обязательное поле'
          }
        },
      }
    }
  },
  computed: {
    ...mapState({
      user: state => state.user
    }),
    ...mapGetters({
      isAlive: 'user/isTokenAlive',
      isAuthenticated: 'user/isAuthenticated',
    }),
    // from () {
    //   // eslint-disable-next-line no-prototype-builtins
    //   if (this.$route.query.hasOwnProperty('from')) {
    //     return this.$route.query.from
    //   } else {
    //     return null
    //   }
    // },
    from() {
      // Проверяем, есть ли параметр "from" в URL
      // eslint-disable-next-line no-prototype-builtins
      if (this.$route.query.hasOwnProperty('from')) {
        // Получаем индекс символа "?from=" в значении "url"
        const indexOfFrom = document.location.href.indexOf('?from=');

        // Если символ найден, возвращаем значение "from" от этой позиции
        if (indexOfFrom !== -1) {
          return document.location.href.slice(indexOfFrom + 6) ?? this.$route.query.from;
        } else {
          // В противном случае, возвращаем значение "from" как есть
          return this.$route.query.from;
        }
      } else {
        return null;
      }
    }
  },
  methods: {
    ...mapActions({
      authLogin: 'user/AUTH_REQUEST',
      checkUserConfirmation: 'user/checkUserConfirmation'
    }),
    saveAgree(){
      let domain = null
      if(process.env.VUE_APP_COOKIE_DOMAIN !== undefined){
        domain = process.env.VUE_APP_COOKIE_DOMAIN;
      }
       cookies.set(
        'acceptMuctr',
        {
          acceptMuctr: true,
        },
        "7d",
        null,
        domain,
        null
      );
      this.sheet = false
    },
    checkConfirmation () {
      this.checkUserConfirmation({
        finalizer: (data) => {
          if (data.need_acquaintance) {
            if (this.from !== null) {
              document.location.href = process.env.VUE_APP_CONF_LINK + '?redirect=' + this.from
            } else if (this.$route.query.redirect !== undefined) {
              document.location.href = process.env.VUE_APP_CONF_LINK + '?redirect=' + this.$route.query.redirect
            } else {
              document.location.href = process.env.VUE_APP_CONF_LINK
            }
          } else {
            this.endRedirect()
          }
        },
        catcher: () => {
          this.endRedirect()
        }
      })
    },
    endRedirect() {
      if (this.$store.getters['user/isNeedChangePassword']) {
        this.$router.push({name: names.NEED_CHANGE_PASSWORD.BASE, query: this.$route.query.redirect})
      } else if (this.from !== null) {
        document.location.href = this.from
      } else if (this.$route.query.redirect !== undefined) {
        this.$router.push({path: this.$route.query.redirect})
      } else {
        goToPortalPage()
      }
      this.loading = false
    },
    login: function () {
      this.loading = true
      let user = {
        username: String(this.username).trim().replace('@muctr.ru', ''),
        password: String(this.password).trim()
      }
      this.authLogin(
        {
          user,
          finalizer: () => {
            this.saveAgree();
            this.checkConfirmation()
          },
          catcher: () => {
            this.loading = false
          }
        }
      )
    },
    symbControl: function (value) {
      if (value === null) {
        return true
      }
      return !(value.includes(' ') || value.includes('\n'))
    }
  },
  // eslint-disable-next-line vue/order-in-components
  created() {
    const now = new Date()
    if (now.getMonth() + 1 > 11 || now.getMonth() + 1 < 3) {
      let count = (this.$vuetify.display.smAndDown ? 40 : 75)
      const snowflakes = new Snowflakes({
        color: '#5ECDEF', // Default: "#5ECDEF"
        count: count, // 100 snowflakes. Default: 50
        minOpacity: 0.1, // From 0 to 1. Default: 0.6
        maxOpacity: 0.95, // From 0 to 1. Default: 1
        minSize: 5, // Default: 10
        maxSize: 18, // Default: 25
        rotation: true, // Default: true
        speed: 1.5, // The property affects the speed of falling. Default: 1
        wind: true, // Without wind. Default: true
        zIndex: 0, // Default: 9999,
        autoResize: true // Default: true
      })
      snowflakes.start()
    }
    if (now.getMonth() + 1 > 6 && now.getMonth() + 1 < 10) {
      this.priem_alert = true
    }
    if (this.$route.params.msg) {
      setTimeout(() => {
        this.msg = true
      }, 500)
    }

    let acceptMuctr = cookies.get("acceptMuctr");
    if (acceptMuctr !== undefined && acceptMuctr !== null) {
      this.sheet = false
    }

  },
}
</script>

<style scoped lang="scss">
.left__container {
  display: flex;
  justify-content: center;
  align-items: center;
  max-width: 960px;
  min-height: 860px;
  background: white;
  border-top-right-radius: 50px;
  border-bottom-right-radius: 50px;
  z-index: 1;
}

.left__img {
  width: 234px;
  height: 180px;
  margin-bottom: 50px;
}

.left__inputs {
  width: 420px;
}

.errors__container {
  margin-bottom: 30px;
  height: 16px;
}

.inputs__error {
  padding: 0;
  color: #D64141;
  font-size: 14px;
  font-style: normal;
  font-weight: 400;
  line-height: 16px;
}

.recovery__text {
  margin-top: 3px;
  margin-bottom: 25px;
  color: #1D71B8;
  font-size: 14px;
  font-style: normal;
  font-weight: 400;
  line-height: 16px;
  text-decoration-line: underline;
}

.auth__btn {
  width: 420px;
  height: 60px;
  border-radius: 10px;
  background: #1D71B8 !important;
  box-shadow: 0 4px 4px 0 rgba(0, 0, 0, 0.25);
  color: #FFF;
  font-size: 18px;
  font-style: normal;
  font-weight: 700;
  line-height: normal;

  &:hover {
    background: #045396 !important;
    box-shadow: 0 5px 5px 0 rgba(0, 0, 0, 0.5);
  }

  &:active {
    background: #045396 !important;
  }
}

.info__text {
  margin-top: 160px;
  padding: 0 180px;
  color: #7C7C7C;
  text-align: center;
  font-size: 14px;
  font-style: normal;
  font-weight: 400;
  line-height: normal;

  &--link {
    color: #1D71B8;
    text-decoration: none;
  }
}

.right__container {
  min-height: 860px;
  background: url("/src/assets/right_bg.png") no-repeat;
  background-size: cover;
  margin-left: -56px;
  z-index: 0;
  display: flex;
  justify-content: center;
  align-items: center;
}

.right__text {
  padding: 0 150px 0 206px;
  text-align: left;
  color: #FFF;
}

.right__title {
  font-size: 64px;
  font-style: normal;
  font-weight: 700;
  line-height: normal;
  letter-spacing: 2.56px;
}

.right__subtitle {
  font-size: 36px;
  font-style: normal;
  font-weight: 700;
  line-height: normal;
  letter-spacing: 1.44px;
}

.right__welcome_text {
  margin: 25px 0;
  font-size: 18px;
  font-style: normal;
  font-weight: 700;
  line-height: normal;
}

.right__info_text {
  margin-bottom: 50px;
  font-size: 16px;
  font-style: normal;
  font-weight: 400;
  line-height: normal;
  letter-spacing: 0.72px;

  &--link {
    text-decoration-line: underline;
    color: #FFFFFF;
    font-size: 18px;
    font-weight: 700;
  }
}

.abitur__btn {
  margin: 50px 0;
  display: inline-flex;
  padding: 17px 20px;
  align-items: flex-start;
  gap: 10px;
  border-radius: 10px;
  background: #FFF;
  box-shadow: 0 5px 10px 0 rgba(0, 0, 0, 0.15);
  color: #1D71B8;
  font-size: 18px;
  font-style: normal;
  font-weight: 500;
  line-height: normal;
  letter-spacing: 0.72px;
  cursor: pointer;
  text-decoration: none;

  &:hover {
    box-shadow: 0 7px 13px 0 rgba(0, 0, 0, 0.35);
  }
}

.mobile__bg {
  background: url("/src/assets/right_bg.png") no-repeat;
  background-size: cover;
  min-height: 100%;
  display: flex;
  align-items: center;
}

.mobile__container {
  max-width: 660px;
}

.technic_work__container {
  padding: 0 50px;
  margin: 100px auto;
}

.technic_work__title {
  margin-bottom: 10px;
  color: #1D71B8;
  text-align: center;
  font-size: 36px;
  font-style: normal;
  font-weight: 400;
  line-height: normal;
}

.technic_work__subtitle {
  color: #969696;
  text-align: center;
  font-size: 18px;
  font-style: normal;
  font-weight: 400;
  line-height: normal;
}

@media screen and (max-width: 1440px) {
  .left__container {
    min-height: 600px;
  }

  .left__img {
    width: 194px;
    height: 150px;
    margin-bottom: 30px;
  }

  .info__text {
    margin-top: 50px;
  }

  .right__container {
    min-height: 600px;
    max-width: 700px;
  }

  .right__text {
    padding: 0 100px 0 156px;
    text-align: left;
    color: #FFF;
  }

  .right__title {
    font-size: 44px;
    letter-spacing: 1.76px;
  }

  .right__subtitle {
    font-size: 26px;
    letter-spacing: 0.84px;
  }

  .right__welcome_text {
    font-size: 16px;
  }
}

@media screen and (max-width: 1279px) {
  .left__container {
    padding-top: 20px !important;
    max-height: 584px;
    border-radius: 25px;
  }

  .left__img {
    width: 130px;
    height: 100px;
    margin-bottom: 15px;
  }
  .errors__container {
    margin-bottom: 10px;
    height: 16px;
  }

  .info__text {
    margin-top: 25px;
    margin-bottom: 50px;
    padding: 0 40px;
  }

  .right__title {
    text-align: left;
    color: #FFFFFF;
    margin: 50px 0 10px;
    padding: 0 30px;
    font-size: 30px;
    letter-spacing: 1.28px;
  }

  .right__subtitle {
    text-align: left;
    color: #FFFFFF;
    margin-bottom: 50px;
    padding: 0 30px;
    font-size: 20px;
    letter-spacing: 0.8px;
  }

  .right__welcome_text {
    text-align: left;
    color: #FFFFFF;
    padding: 0 30px;
    margin: 55px 0 25px;
    font-size: 14px;
    font-style: normal;
    font-weight: 700;
    line-height: normal;
  }

  .right__info_text {
    text-align: left;
    padding: 0 30px;
    color: #FFFFFF;
    font-size: 14px;
    letter-spacing: normal;

    &--link {
      font-size: 16px;
    }
  }
}

@media screen and (max-width: 730px) {
  .mobile__container {
    max-width: 500px;
  }

  .left__inputs {
    width: 300px;
  }

  .inputs__error {
    max-width: 280px;
  }

  .auth__btn {
    width: 300px;
  }

  .technic_work__container {
    padding: 0 30px;
    margin: 50px auto;
  }

  .technic_work__title {
    font-size: 28px;
  }
}

@media screen and (max-width: 370px) {
  .right__title {
    margin: 30px 0 10px;
    padding: 0 15px;
    font-size: 26px;
  }

  .right__subtitle {
    margin-bottom: 30px;
    padding: 0 15px;
    font-size: 18px;
  }
}
</style>
