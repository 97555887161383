import helper from './helper'

let getAccountsUrlWithParameters = helper.getAccountsUrlWithParameters

export default {
  getAccountsUrlWithParameters,
  AUTHENTICATE: {
    AUTHENTICATION: {
      LOGIN:
        (gets = null) => getAccountsUrlWithParameters(
          'authenticate|authentication|login',
          "post",
          gets
        ),
      LOGOUT:
        (gets = null) => getAccountsUrlWithParameters(
          'authenticate|authentication|logout',
          'post',
          gets
        ),
      SESSION: {
        ALIVE:
          (gets = null) => getAccountsUrlWithParameters(
            'authenticate|authentication|session|alive',
            'get',
            gets
          )
      },
      TOKEN: {
        ALIVE:
          (gets = null) => getAccountsUrlWithParameters(
            'authenticate|authentication|token|alive',
            'post',
            gets
          )
      }
    }
  },
}
