const getUrlObject = (urls, instancename, urlname, action) => {
  return urls[instancename]
    .find(x => x.name === urlname).methods
    .find(x => x.action === action)
}

const state = {
  urls: {}
}

const mutations = {
  appendUrls (state, urls) {
    const new_keys = Object.keys(urls)
    const existed_keys = Object.keys(state.urls)
    for (const i in new_keys) {
      if (!(new_keys[i] in existed_keys)) {
        state.urls[new_keys[i]] = urls[new_keys[i]]
      }
    }
  },
  clearUrls (state) {
    state.urls = {}
  }
}

const getters = {
  getInstance: (state) => (instance) => {
    try {
      const urls = state.urls[instance]
      urls.getUrl = function (name) {
        return this[name]
      }
      return urls
    } catch (e) {
      return {}
    }
  },

  getPattern: (state) => (instancename, urlname, action) => {
    try {
      return getUrlObject(state.urls, instancename, urlname, action).pattern
    } catch (e) {
      return ''
    }
  },

  getAllowed: (state) => (instancename, urlname, action) => {
    try {
      return getUrlObject(state.urls, instancename, urlname, action).allowed
    } catch (e) {
      return ''
    }
  },

  getUrl: (state) => (instancename, urlname, action) => {
    try {
      return getUrlObject(state.urls, instancename, urlname, action)
    } catch (e) {
      return null
    }
  },

  checkAlreadyLoaded: (state) => (instancename) => {
    return instancename in state.urls
  }
}

export default {
  namespaced: true,
  state,
  mutations,
  getters
}
