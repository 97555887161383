import {getHttpHeaders} from "@/helper/requests";

const HOST_ACCOUNT = process.env.VUE_APP_API_HOST;

const state = {
  error: null,
  loading: false
}

const getters = {
  isLoading: state => state.loading
}

const actions = {
  AUTHORIZE: ({commit}, {data = undefined}) => {
    commit('SET_LOADING')
    const headers = getHttpHeaders()
    return new Promise((resolve, reject) => {
      fetch(
        `${HOST_ACCOUNT}oauth/authorize/`,
        {
          headers: headers,
          body: JSON.stringify(data),
          method: 'POST'
        }
      ).then(resp => {
        if (!resp.ok) {
          commit('SET_NOT_LOADING');
          reject(resp.json())
        } else {
          commit('SET_NOT_LOADING');
          return resolve(resp.json())
        }
      }).catch(error => {
        commit('SET_NOT_LOADING');
        reject(error);
      });
    })
  },
  CHECK_ACCESS: ({commit}, {data = undefined}) => {
    commit('SET_LOADING')
    const headers = getHttpHeaders()
    return new Promise((resolve, reject) => {
      fetch(
        `${HOST_ACCOUNT}oauth/check/access/`,
        {
          headers: headers,
          body: JSON.stringify(data),
          method: 'POST'
        }
      ).then(resp => {
        if (!resp.ok) {
            commit('SET_NOT_LOADING');
            reject(resp.json())
        } else {
          commit('SET_NOT_LOADING');
          return resolve(resp.json())
        }
      }).catch(error => {
        commit('SET_NOT_LOADING');
        reject(error);
      });
    })
  },
}

const mutations = {
  SET_LOADING: (state) => {
    state.loading = true
  },
  SET_NOT_LOADING: (state) => {
    state.loading = false
  }
}

export default {
  namespaced: true,
  state,
  getters,
  actions,
  mutations
}
