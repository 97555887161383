import {ACCOUNTS_INSTANCE} from "@/helper/consts";
import names from '@/modules/core/routers/names'
import Login from '@/modules/core/components/Login'

export default ([
  {
    path: '/',
    name: names.MAIN,
    component: Login,
    meta: {
      requiredAuth: false,
      instances: [
        ACCOUNTS_INSTANCE
      ]
    },
    children:[

    ]
  },
])
